import config, { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  actions: {
    async getTalonarios({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/administracion/talonarios?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )
      return response.json()
    },

    async getTipos() {
      const response = await fetch(
        `${API_URL}/administracion/tiposTalonarios`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async validarRango({}, payload) {
      const response = await fetch(`${API_URL}/administracion/validarRango`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async nuevoTalonario({}, payload) {
      const response = await fetch(`${API_URL}/administracion/nuevoTalonario`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async editarTalonario({}, payload) {
      const response = await fetch(`${API_URL}/administracion/editar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async validarRangoEditar({}, payload) {
      const response = await fetch(`${API_URL}/administracion/validarRangoEditar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async inhabilitarTalonario({}, payload) {
      const response = await fetch(`${API_URL}/administracion/inhabilitar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async habilitarTalonario({}, payload) {
      const response = await fetch(`${API_URL}/administracion/habilitar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

  }
}
