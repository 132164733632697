import Vue from "vue";
import Vuex from "vuex";
import config, { API_URL } from "../../config";
import { database, auth } from "../firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { ref, get, set, child } from "firebase/database";
import { Encrypt, Decrypt } from "../util/aes";
import { VueEasyJwt } from "vue-easy-jwt";

// modulos
import genericos from "../modules/genericos";
import usuarios from "../modules/usuarios";
import roles from "../modules/admin/roles";
import menu_lateral from "../modules/admin/menu_lateral";
import rutas from "../modules/admin/rutas";
import funciones from "../modules/admin/funciones";
import cartera from "../modules/pa/cartera";
import comisiones from "../modules/pa/comisiones";
import patentamiento from "../modules/patentamiento";
import archivos from "../modules/archivos";
import recuperos from "../modules/planes/recuperos";
import ventasPA from "../modules/planes/ventas";
import mantenimientoPA from "../modules/planes/mantenimiento";
import dashboardPA from "../modules/planes/dashboard";
import taller from "../modules/taller/taller";
import recibos from "../modules/planes/recibos";
import carteraPA from "../modules/planes/cartera";
import cobros from "../modules/cobros";
import usados from "../modules/usados";
import preciosModelos from '../modules/pa/preciosModelos'
import modelos from '../modules/pa/modelos'
import comprobantesAfip from "../modules/administracion/comprobantesAfip";
import retencionesPercepciones from "../modules/administracion/retencionesPercepciones";
import ventasSalon from '../modules/comisiones-salon/ventasSalon';
import comisionesVN from '../modules/comisiones-salon/comisionesVN';
import talonarios from "../modules/administracion/talonarios";
import asignacionFormularios from "../modules/administracion/asignacionFormularios";

const jwt = new VueEasyJwt();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    menu: null,
    menu_expand: false,
    menu_nombres: "",
    color_perfil: "",
    token: "",
    jarvis_token: "",
    notificaciones: [],
    username: "",
    empresas: [],
    familias: [],
    colores_perfil: [
      "grey",
      "orange",
      "pink",
      "light-green",
      "cyan",
      "purple",
      "teal",
      "blue-grey",
    ],
    usuario: {},
    snackbar: {
      value: false,
      text: "",
      color: "",
      timeout: 3000,
    },
    rules: {
      required: (value) => !!value || "Campo requerido",
      requiredArray: (value) => !!value.length || "Campo requerido",
    },
  },
  mutations: {
    set_token(state, payload) {
      state.token = payload;
      localStorage.setItem("token", JSON.stringify(payload));
      if (payload) {
        const token = jwt.decodeToken(payload);
        state.usuario = token.data;
        state.username = Decrypt(token.credenciales.username);
      } else {
        state.usuario = {};
        state.username = "";
      }
    },
    set_jarvis_token(state, payload) {
      state.jarvis_token = `bearer ${payload}`;
      localStorage.setItem(
        "jarvis_token",
        `bearer ${payload}`
      );
    },
    set_notificaciones(state, payload) {
      state.notificaciones = payload
      localStorage.setItem('notificaciones', JSON.stringify(payload))
    },
    delete_notificacion(state, payload) {
      const index = state.notificaciones.indexOf(payload)
      state.notificaciones.splice(index, 1)
      localStorage.setItem('notificaciones', JSON.stringify(state.notificaciones))
    },
    set_menu(state, payload) {
      state.menu = payload;
      localStorage.setItem("menu", JSON.stringify(payload));
      if (state.menu) {
        let menu_nombres = [];
        state.menu.forEach((item) => {
          if (item.children) {
            item.children.forEach((child) => {
              menu_nombres.push({
                nombre: child.text,
                ruta: child.href,
              });
            });
          } else {
            menu_nombres.push({
              nombre: item.text,
              ruta: item.href,
            });
          }
        });
        state.menu_nombres = menu_nombres;
      }
    },
    set_empresas(state, payload) {
      state.empresas = payload;
      localStorage.setItem("empresas", JSON.stringify(payload));
    },
    set_familias(state, payload) {
      state.familias = payload;
      localStorage.setItem("familias", JSON.stringify(payload));
    },
    set_dark_theme(state, payload) {
      localStorage.setItem("dark", JSON.stringify(payload));
    },
    set_color_perfil(state, payload) {
      state.color_perfil = payload;
      localStorage.setItem("color", JSON.stringify(payload));
    },
    set_snackbar(state, payload) {
      state.snackbar = payload;
    },
  },
  actions: {
    async set_theme({ commit }, payload) {
      return await new Promise(async (resolve, reject) => {
        await set(
          ref(database, "usuarios/" + auth.currentUser.uid + "/tema_oscuro"),
          payload
        )
          .then(() => {
            commit("set_dark_theme", payload);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async get_user_data({ state, commit, dispatch }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/user`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: state.token,
            },
          });

          const data = await res.json();

          if (data.exito == 1) {
            commit("set_menu", data.menu);
            commit("set_empresas", data.empresas);
            commit("set_familias", data.familias);
            await dispatch('get_notificaciones_noleidas')
            resolve();
          } else {
            commit("set_menu", []);
            commit("set_empresas", []);
            commit("set_familias", []);
            reject(data);
          }
        } catch (error) {
          commit("set_menu", []);
          commit("set_empresas", []);
          commit("set_familias", []);
          reject(error);
        }
      });
    },
    async login({ state, commit, dispatch }, payload) {
      return await new Promise(async (resolve, reject) => {
        await signInWithEmailAndPassword(auth, payload.user, payload.pass)
          .then(async (userCredential) => {
            // buscamos la configuracion del usuario
            const uid = userCredential.user.uid;
            const snapshot = await get(child(ref(database), "usuarios/" + uid));

            let conf = {};
            // si no encontro el usuario, creamos su configuracion (este caso se da unicamente cuando inicia sesion por primera vez)
            if (snapshot.exists()) {
              conf = snapshot.val();
            } else {
              conf = {
                tema_oscuro: false,
                color: state.colores_perfil[Math.floor(Math.random() * 8)],
              };
              await set(ref(database, "usuarios/" + uid), conf);
            }

            // enviamos a la api el uid y email del usuario
            const res = await fetch(`${config.API_URL}/user/token`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                email: Encrypt(userCredential.user.email),
                uid: Encrypt(uid),
              }),
            });

            const data = await res.json();

            // si la respuesta de la api fue correcta seteamos la configuracion del usuario y pedimos el resto de los datos
            if (data.exito == 1) {
              localStorage.setItem(
                "usuario",
                JSON.stringify(userCredential.user.email)
              );
              commit("set_token", data.token);
              commit("set_dark_theme", conf.tema_oscuro);
              commit("set_color_perfil", conf.color);
              await dispatch("get_user_data");
              resolve();
            } else {
              // en caso de error debemos deslogear al usuario
              await dispatch("logout");
              reject(data);
            }
          })
          .catch(async (error) => {
            // cacheamos los errores del login de firebase para traducirlos
            switch (error.code) {
              case "auth/user-not-found":
                error.message = "Usuario y/o clave incorrectos";
                break;
              case "auth/wrong-password":
                error.message = "Usuario y/o clave incorrectos";
                break;
              case "auth/too-many-requests":
                error.message =
                  "Superó el límite de intentos de inicio de sesión, intente más tarde";
                break;
              case "auth/invalid-email":
                error.message = "Ingrese un email válido";
                break;
              case "auth/user-disabled":
                error.message = "Usuario bloqueado";
                break;
              case undefined:
                // si el code del error no existe significa que no es un error del signInWithEmailAndPassword
                await dispatch("logout");
                break;
            }
            reject(error);
          });
      });
    },
    async logout({ commit }) {
      return await new Promise(async (resolve, reject) => {
        await signOut(auth)
          .then(() => {
            // limpia los valores almacenados
            commit("set_token", null);
            commit("set_dark_theme", false);
            commit("set_menu", []);
            commit("set_empresas", []);
            commit("set_familias", []);
            commit("set_jarvis_token", null)
            commit('set_notificaciones', [])
            localStorage.setItem('vehiculos', [])
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    show_snackbar({ commit }, payload) {
      let snackbar = {
        value: true,
        text: payload.text,
        color: payload.color,
        timeout: 3000,
      };
      if (payload.timeout) {
        snackbar.timeout = payload.timeout;
      }
      commit("set_snackbar", snackbar);
    },
    loadLocalStorage({ commit }) {
      commit("set_token", JSON.parse(localStorage.getItem("token")));
      commit("set_menu", JSON.parse(localStorage.getItem("menu")));
      commit("set_empresas", JSON.parse(localStorage.getItem("empresas")));
      commit("set_familias", JSON.parse(localStorage.getItem("familias")));
      commit("set_color_perfil", JSON.parse(localStorage.getItem("color")));
      commit('set_notificaciones', JSON.parse(localStorage.getItem('notificaciones')))
    },

    async get_jarvis_token({ state, commit }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/tokenJarvis`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: state.token,
            },
          });

          const data = await res.json()

          if (data.exito === 1) {
            commit("set_jarvis_token", data.token)
            resolve()
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error);
        }
      })
    },

    async marcar_notificacion_leida({ state }, payload) {

      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/notificaciones/marcarNotificacionLeida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },

    delete_notificacion(state, payload) {
      const index = state.notificaciones.indexOf(payload)
      state.notificaciones.splice(index, 1)
      localStorage.setItem('notificaciones', JSON.stringify(state.notificaciones))
    },

    

    async get_notificaciones({ state }, payload) {
      try {
        const res = await fetch(`${config.API_URL}/notificaciones`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.token
          },
          body: JSON.stringify(payload)
        })

        const data = await res.json()
        return data

      } catch (error) {
        return {
          exito: 0,
          message: error.message
        }
      }
    },

    async get_notificaciones_noleidas({ dispatch, commit }) {
      await dispatch('get_notificaciones', { estado: 1 })
        .then(res => {
          commit('set_notificaciones', [...res.data])
        })
        .catch(() => {
          commit('set_notificaciones', [])
        })
    },
  },
  modules: {
    genericos,
    usuarios,
    roles,
    menu_lateral,
    rutas,
    funciones,
    cartera,
    comisiones,
    patentamiento,
    archivos,
    recuperos,
    ventasPA,
    mantenimientoPA,
    dashboardPA,
    taller,
    recibos,
    carteraPA,
    cobros,
    usados,
    preciosModelos,
    modelos,
    comprobantesAfip,
    retencionesPercepciones,
    ventasSalon,
    comisionesVN,
    talonarios,
    asignacionFormularios
  },
  getters: {
    is_logged(state) {
      return !jwt.isExpired(state.token);
    },
    una_empresa(state) {
      return state.empresas.length == 1;
    },
    una_familia(state) {
      return state.familias.length == 1;
    },
  },
});
