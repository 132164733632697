import config from '../../config'
import store from '../store'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    vendedores: [],
    supervisores: [],
    conceptos: [],
    tipos_doc: [],
    tipos_sexo: [],
    sucursales: [],
    tipos_est_civil: [],
    financieras: [],
  },
  mutations: {
    set_vendedores (state, payload) {
      order_list_by(payload, 'nombre')
      state.vendedores = payload
    },
    set_supervisores (state, payload) {
      order_list_by(payload, 'nombre')
      state.supervisores = payload
    },
    set_conceptos (state, payload) {
      order_list_by(payload, 'nombre')
      state.conceptos = payload
    },
    set_tipos_doc (state, payload) {
      state.tipos_doc = payload
    },
    set_tipos_sexo (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_sexo = payload
    },
    set_sucursales (state, payload) {
      state.sucursales = payload
    },
    set_estados_civiles (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_est_civil = payload
    },
    set_financieras (state, payload) {
      state.financieras = payload
    }
  },
  actions: {
    async actualizar_servicios ({}, empresa) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/${empresa}/actualizarServicios`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_instructivos () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/instructivos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_financieras ({ state, commit }) {
      try {
        if (state.financieras.length == 0) {
          const res = await fetch(`${config.API_URL}/financieras`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_financieras', data.data)
          } else {
            commit('set_financieras', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_financieras', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_estados_civiles ({ state, commit }) {
      try {
        if (state.tipos_est_civil.length == 0) {
          const res = await fetch(`${config.API_URL}/tiposEstadoCivil`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados_civiles', data.data)
          } else {
            commit('set_estados_civiles', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados_civiles', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_sucursales ({ state, commit }) {
      try {
        if (state.sucursales.length == 0) {
          const res = await fetch(`${config.API_URL}/sucursales`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_sucursales', data.data)
          } else {
            commit('set_sucursales', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_sucursales', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos_doc ({ state, commit }) {
      try {
        if (state.tipos_doc.length == 0) {
          const res = await fetch(`${config.API_URL}/tiposDoc`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos_doc', data.data)
          } else {
            commit('set_tipos_doc', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos_doc', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos_sexo ({ state, commit }) {
      try {
        if (state.tipos_sexo.length == 0) {
          const res = await fetch(`${config.API_URL}/tiposSexo`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos_sexo', data.data)
          } else {
            commit('set_tipos_sexo', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos_sexo', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_conceptos ({ state, commit }) {
      try {
        if (state.conceptos.length == 0) {
          const res = await fetch(`${config.API_URL}/conceptos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_conceptos', data.data)
          } else {
            commit('set_conceptos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_conceptos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_vendedores ({ state, commit }) {
      try {
        if (state.vendedores.length == 0) {
          const res = await fetch(`${config.API_URL}/vendedores`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_vendedores', data.data)
          } else {
            commit('set_vendedores', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_vendedores', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_supervisores ({ state, commit }) {
      try {
        if (state.supervisores.length == 0) {
          const res = await fetch(`${config.API_URL}/supervisores`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_supervisores', data.data)
          } else {
            commit('set_supervisores', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_supervisores', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}